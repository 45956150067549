export const CREATE_PRODUCT_LOADING = "CREATE_PRODUCT_LOADING";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_ERROR = "CREATE_PRODUCT_ERROR";

export const GET_ALL_PRODUCT_LOADING = "GET_ALL_PRODUCT_LOADING";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_ERROR = "GET_ALL_PRODUCT_ERROR";

export const GET_PRODUCT_LOADING = "GET_PRODUCT_LOADING";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

export const UPDATE_PRODUCT_LOADING = "UPDATE_PRODUCT_LOADING";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_ERROR";

export const CREATE_PRODUCT_CATEGORY_LOADING =
  "CREATE_PRODUCT_CATEGORY_LOADING";
export const CREATE_PRODUCT_CATEGORY_SUCCESS =
  "CREATE_PRODUCT_CATEGORY_SUCCESS";
export const CREATE_PRODUCT_CATEGORY_ERROR = "CREATE_PRODUCT_CATEGORY_ERROR";

export const GET_ALL_PRODUCT_CATEGORY_LOADING =
  "GET_ALL_PRODUCT_CATEGORY_LOADING";
export const GET_ALL_PRODUCT_CATEGORY_SUCCESS =
  "GET_ALL_PRODUCT_CATEGORY_SUCCESS";
export const GET_ALL_PRODUCT_CATEGORY_ERROR = "GET_ALL_PRODUCT_CATEGORY_ERROR";

export const GET_PRODUCT_CATEGORY_LOADING = "GET_PRODUCT_CATEGORY_LOADING";
export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_ERROR = "GET_PRODUCT_CATEGORY_ERROR";

export const UPDATE_PRODUCT_CATEGORY_LOADING =
  "UPDATE_PRODUCT_CATEGORY_LOADING";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS =
  "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_ERROR = "UPDATE_PRODUCT_CATEGORY_ERROR";

export const CREATE_PRODUCT_COMPONENT_LOADING =
  "CREATE_PRODUCT_COMPONENT_LOADING";
export const CREATE_PRODUCT_COMPONENT_SUCCESS =
  "CREATE_PRODUCT_COMPONENT_SUCCESS";
export const CREATE_PRODUCT_COMPONENT_ERROR = "CREATE_PRODUCT_COMPONENT_ERROR";

export const UPDATE_PRODUCT_COMPONENT_LOADING =
  "UPDATE_PRODUCT_COMPONENT_LOADING";
export const UPDATE_PRODUCT_COMPONENT_SUCCESS =
  "UPDATE_PRODUCT_COMPONENT_SUCCESS";
export const UPDATE_PRODUCT_COMPONENT_ERROR = "UPDATE_PRODUCT_COMPONENT_ERROR";

export const DELETE_PRODUCT_COMPONENT_LOADING =
  "DELETE_PRODUCT_COMPONENT_LOADING";
export const DELETE_PRODUCT_COMPONENT_SUCCESS =
  "DELETE_PRODUCT_COMPONENT_SUCCESS";
export const DELETE_PRODUCT_COMPONENT_ERROR = "DELETE_PRODUCT_COMPONENT_ERROR";

export const LOGIN_USER_LOADING = "LOGIN_USER_LOADING";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const REFRESH_USER_TOKEN_LOADING = "REFRESH_USER_TOKEN_LOADING";
export const REFRESH_USER_TOKEN_SUCCESS = "REFRESH_USER_TOKEN_SUCCESS";
export const REFRESH_USER_TOKEN_ERROR = "REFRESH_USER_TOKEN_ERROR";

export const CREATE_PRODUCT_SUPPLIER_LOADING =
  "CREATE_PRODUCT_SUPPLIER_LOADING";
export const CREATE_PRODUCT_SUPPLIER_SUCCESS =
  "CREATE_PRODUCT_SUPPLIER_SUCCESS";
export const CREATE_PRODUCT_SUPPLIER_ERROR = "CREATE_PRODUCT_SUPPLIER_ERROR";

export const GET_ALL_PRODUCT_SUPPLIERS_LOADING =
  "GET_ALL_PRODUCT_SUPPLIERS_LOADING";
export const GET_ALL_PRODUCT_SUPPLIERS_SUCCESS =
  "GET_ALL_PRODUCT_SUPPLIERS_SUCCESS";
export const GET_ALL_PRODUCT_SUPPLIERS_ERROR =
  "GET_ALL_PRODUCT_SUPPLIERS_ERROR";

export const GET_PRODUCT_SUPPLIER_LOADING = "GET_PRODUCT_SUPPLIER_LOADING";
export const GET_PRODUCT_SUPPLIER_SUCCESS = "GET_PRODUCT_SUPPLIER_SUCCESS";
export const GET_PRODUCT_SUPPLIER_ERROR = "GET_PRODUCT_SUPPLIER_ERROR";

export const UPDATE_PRODUCT_SUPPLIER_LOADING =
  "UPDATE_PRODUCT_SUPPLIER_LOADING";
export const UPDATE_PRODUCT_SUPPLIER_SUCCESS =
  "UPDATE_PRODUCT_SUPPLIER_SUCCESS";
export const UPDATE_PRODUCT_SUPPLIER_ERROR = "UPDATE_PRODUCT_SUPPLIER_ERROR";

export const GET_EMPLOYERS_LOADING = "GET_EMPLOYERS_LOADING";
export const GET_EMPLOYERS_SUCCESS = "GET_EMPLOYERS_SUCCESS";
export const GET_EMPLOYERS_ERROR = "GET_EMPLOYERS_ERROR";

export const CREATE_EMPLOYERS_LOADING = "CREATE_EMPLOYERS_LOADING";
export const CREATE_EMPLOYERS_SUCCESS = "CREATE_EMPLOYERS_SUCCESS";
export const CREATE_EMPLOYERS_ERROR = "CREATE_EMPLOYERS_ERROR";

export const UPDATE_EMPLOYERS_LOADING = "UPDATE_EMPLOYERS_LOADING";
export const UPDATE_EMPLOYERS_SUCCESS = "UPDATE_EMPLOYERS_SUCCESS";
export const UPDATE_EMPLOYERS_ERROR = "UPDATE_EMPLOYERS_ERROR";

export const GET_DASHBOARD_COUNT_LOADING = "GET_DASHBOARD_COUNT_LOADING";
export const GET_DASHBOARD_COUNT_SUCCESS = "GET_DASHBOARD_COUNT_SUCCESS";
export const GET_DASHBOARD_COUNT_ERROR = "GET_DASHBOARD_COUNT_ERROR";

export const GET_QUOTE_WITHIN_LOADING = "GET_QUOTE_WITHIN_LOADING";
export const GET_QUOTE_WITHIN_SUCCESS = "GET_QUOTE_WITHIN_SUCCESS";
export const GET_QUOTE_WITHIN_ERROR = "GET_QUOTE_WITHIN_ERROR";

export const GET_QUOTE_OUTSIDE_LOADING = "GET_QUOTE_OUTSIDE_LOADING";
export const GET_QUOTE_OUTSIDE_SUCCESS = "GET_QUOTE_OUTSIDE_SUCCESS";
export const GET_QUOTE_OUTSIDE_ERROR = "GET_QUOTE_OUTSIDE_ERROR";

export const GET_QUOTE_REQUEST_HISTORY_LOADING =
  "GET_QUOTE_REQUEST_HISTORY_LOADING";
export const GET_QUOTE_REQUEST_HISTORY_SUCCESS =
  "GET_QUOTE_REQUEST_HISTORY_SUCCESS";
export const GET_QUOTE_REQUEST_HISTORY_ERROR =
  "GET_QUOTE_REQUEST_HISTORY_ERROR";

export const GET_QUOTE_AWAITING_CUSTOMER_LOADING =
  "GET_QUOTE_AWAITING_CUSTOMER_LOADING";
export const GET_QUOTE_AWAITING_CUSTOMER_SUCCESS =
  "GET_QUOTE_AWAITING_CUSTOMER_SUCCESS";
export const GET_QUOTE_AWAITING_CUSTOMER_ERROR =
  "GET_QUOTE_AWAITING_CUSTOMER_ERROR";

export const GET_FILTER_QUOTES_LOADING = "GET_FILTER_QUOTES_LOADING";
export const GET_FILTER_QUOTES_SUCCESS = "GET_FILTER_QUOTES_SUCCESS";
export const GET_FILTER_QUOTES_ERROR = "GET_FILTER_QUOTES_ERROR";

export const GET_QUOTE_BY_ID_LOADING = "GET_QUOTE_BY_ID_LOADING";
export const GET_QUOTE_BY_ID_SUCCESS = "GET_QUOTE_BY_ID_SUCCESS";
export const GET_QUOTE_BY_ID_ERROR = "GET_QUOTE_BY_ID_ERROR";

export const GET_INSTALLATION_WITHIN_LOADING =
  "GET_INSTALLATION_WITHIN_LOADING";
export const GET_INSTALLATION_WITHIN_SUCCESS =
  "GET_INSTALLATION_WITHIN_SUCCESS";
export const GET_INSTALLATION_WITHIN_ERROR = "GET_INSTALLATION_WITHIN_ERROR";

export const GET_INSTALLATION_OUTSIDE_LOADING =
  "GET_INSTALLATION_OUTSIDE_LOADING";
export const GET_INSTALLATION_OUTSIDE_SUCCESS =
  "GET_INSTALLATION_OUTSIDE_SUCCESS";
export const GET_INSTALLATION_OUTSIDE_ERROR = "GET_INSTALLATION_OUTSIDE_ERROR";

export const GET_INSTALLATION_BY_ID_LOADING = "GET_INSTALLATION_BY_ID_LOADING";
export const GET_INSTALLATION_BY_ID_SUCCESS = "GET_INSTALLATION_BY_ID_SUCCESS";
export const GET_INSTALLATION_BY_ID_ERROR = "GET_INSTALLATION_BY_ID_ERROR";

export const GET_TOTAL_PENDING_INSTALLATION_LOADING =
  "GET_TOTAL_PENDING_INSTALLATION_LOADING";
export const GET_TOTAL_PENDING_INSTALLATION_SUCCESS =
  "GET_TOTAL_PENDING_INSTALLATION_SUCCESS";
export const GET_TOTAL_PENDING_INSTALLATION_ERROR =
  "GET_TOTAL_PENDING_INSTALLATION_ERROR";

export const GET_TOTAL_INSTALLED_LOADING = "GET_TOTAL_INSTALLED_LOADING";
export const GET_TOTAL_INSTALLED_SUCCESS = "GET_TOTAL_INSTALLED_SUCCESS";
export const GET_TOTAL_INSTALLED_ERROR = "GET_TOTAL_INSTALLED_ERROR";

export const GET_COMPLAIN_WITHIN_LOADING = "GET_COMPLAIN_WITHIN_LOADING";
export const GET_COMPLAIN_WITHIN_SUCCESS = "GET_COMPLAIN_WITHIN_SUCCESS";
export const GET_COMPLAIN_WITHIN_ERROR = "GET_COMPLAIN_WITHIN_ERROR";

export const GET_COMPLAIN_OUTSIDE_LOADING = "GET_COMPLAIN_OUTSIDE_LOADING";
export const GET_COMPLAIN_OUTSIDE_SUCCESS = "GET_COMPLAIN_OUTSIDE_SUCCESS";
export const GET_COMPLAIN_OUTSIDE_ERROR = "GET_COMPLAIN_OUTSIDE_ERROR";

export const GET_CLOSED_COMPLAIN_LOADING = "GET_CLOSED_COMPLAIN_LOADING";
export const GET_CLOSED_COMPLAIN_SUCCESS = "GET_CLOSED_COMPLAIN_SUCCESS";
export const GET_CLOSED_COMPLAIN_ERROR = "GET_CLOSED_COMPLAIN_ERROR";

export const CREATE_SUPPORT_LOADING = "CREATE_SUPPORT_LOADING";
export const CREATE_SUPPORT_SUCCESS = "CREATE_SUPPORT_SUCCESS";
export const CREATE_SUPPORT_ERROR = "CREATE_SUPPORT_ERROR";

export const CREATE_CUSTOMER_LOADING = "CREATE_CUSTOMER_LOADING";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_ERROR = "CREATE_CUSTOMER_ERROR";

export const CREATE_SALES_TEAM_LOADING = "CREATE_SALES_TEAM_LOADING";
export const CREATE_SALES_TEAM_SUCCESS = "CREATE_SALES_TEAM_SUCCESS";
export const CREATE_SALES_TEAM_ERROR = "CREATE_SALES_TEAM_ERROR";

export const UPDATE_SUPPORT_LOADING = "UPDATE_SUPPORT_LOADING";
export const UPDATE_SUPPORT_SUCCESS = "UPDATE_SUPPORT_SUCCESS";
export const UPDATE_SUPPORT_ERROR = "UPDATE_SUPPORT_ERROR";

export const ACTIVATE_SUPPORT_LOADING = "ACTIVATE_SUPPORT_LOADING";
export const ACTIVATE_SUPPORT_SUCCESS = "ACTIVATE_SUPPORT_SUCCESS";
export const ACTIVATE_SUPPORT_ERROR = "ACTIVATE_SUPPORT_ERROR";

export const DEACTIVATE_SUPPORT_LOADING = "DEACTIVATE_SUPPORT_LOADING";
export const DEACTIVATE_SUPPORT_SUCCESS = "DEACTIVATE_SUPPORT_SUCCESS";
export const DEACTIVATE_SUPPORT_ERROR = "DEACTIVATE_SUPPORT_ERROR";

export const GET_ALL_SUPPORTS_LOADING = "GET_ALL_SUPPORTS_LOADING";
export const GET_ALL_SUPPORTS_SUCCESS = "GET_ALL_SUPPORTS_SUCCESS";
export const GET_ALL_SUPPORTS_ERROR = "GET_ALL_SUPPORTS_ERROR";

export const GET_ALL_CUSTOMERS_LOADING = "GET_ALL_CUSTOMERS_LOADING";
export const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS";
export const GET_ALL_CUSTOMERS_ERROR = "GET_ALL_CUSTOMERS_ERROR";

export const GET_ALL_SALES_AGENTS_LOADING = "GET_ALL_SALES_AGENTS_LOADING";
export const GET_ALL_SALES_AGENTS_SUCCESS = "GET_ALL_SALES_AGENTS_SUCCESS";
export const GET_ALL_SALES_AGENTS_ERROR = "GET_ALL_SALES_AGENTS_ERROR";

export const GET_ALL_SALES_COORDINATOR_LOADING =
  "GET_ALL_SALES_COORDINATOR_LOADING";
export const GET_ALL_SALES_COORDINATOR_SUCCESS =
  "GET_ALL_SALES_COORDINATOR_SUCCESS";
export const GET_ALL_SALES_COORDINATOR_ERROR =
  "GET_ALL_SALES_COORDINATOR_ERROR";

export const GET_ALL_SALES_AGENT_CUSTOMER_LOADING =
  "GET_ALL_SALES_AGENT_CUSTOMER_LOADING";
export const GET_ALL_SALES_AGENT_CUSTOMER_SUCCESS =
  "GET_ALL_SALES_AGENT_CUSTOMER_SUCCESS";
export const GET_ALL_SALES_AGENT_CUSTOMER_ERROR =
  "GET_ALL_SALES_AGENT_CUSTOMER_ERROR";

export const GET_SALES_AGENTS_TRANSACTIONS_LOADING =
  "GET_SALES_AGENTS_TRANSACTIONS_LOADING";
export const GET_SALES_AGENTS_TRANSACTIONS_SUCCESS =
  "GET_SALES_AGENTS_TRANSACTIONS_SUCCESS";
export const GET_SALES_AGENTS_TRANSACTIONS_ERROR =
  "GET_SALES_AGENTS_TRANSACTIONS_ERROR";

export const SALES_AGENTS_COMMISSION_LOADING =
  "SALES_AGENTS_COMMISSION_LOADING";
export const SALES_AGENTS_COMMISSION_SUCCESS =
  "SALES_AGENTS_COMMISSION_SUCCESS";
export const SALES_AGENTS_COMMISSION_ERROR = "SALES_AGENTS_COMMISSION_ERROR";

export const GET_SALES_AGENT_CUSTOMER_PAYOUT_LOADING =
  "GET_SALES_AGENT_CUSTOMER_PAYOUT_LOADING";
export const GET_SALES_AGENT_CUSTOMER_PAYOUT_SUCCESS =
  "GET_SALES_AGENT_CUSTOMER_PAYOUT_SUCCESS";
export const GET_SALES_AGENT_CUSTOMER_PAYOUT_ERROR =
  "GET_SALES_AGENT_CUSTOMER_PAYOUT_ERROR";

export const GET_SALES_TEAM_UNPAID_COMMISSIONS_LOADING =
  "GET_SALES_TEAM_UNPAID_COMMISSIONS_LOADING";
export const GET_SALES_TEAM_UNPAID_COMMISSIONS_SUCCESS =
  "GET_SALES_TEAM_UNPAID_COMMISSIONS_SUCCESS";
export const GET_SALES_TEAM_UNPAID_COMMISSIONS_ERROR =
  "GET_SALES_TEAM_UNPAID_COMMISSIONS_ERROR";

export const APPROVE_QUOTE_LOADING = "APPROVE_QUOTE_LOADING";
export const APPROVE_QUOTE_SUCCESS = "APPROVE_QUOTE_SUCCESS";
export const APPROVE_QUOTE_ERROR = "APPROVE_QUOTE_ERROR";

export const REJECT_QUOTE_LOADING = "REJECT_QUOTE_LOADING";
export const REJECT_QUOTE_SUCCESS = "REJECT_QUOTE_SUCCESS";
export const REJECT_QUOTE_ERROR = "REJECT_QUOTE_ERROR";

export const FACIAL_VERIFY_LOADING = "FACIAL_VERIFY_LOADING";
export const FACIAL_VERIFY_SUCCESS = "FACIAL_VERIFY_SUCCESS";
export const FACIAL_VERIFY_ERROR = "FACIAL_VERIFY_ERROR";

export const MARK_AS_PAID_QUOTE_LOADING = "MARK_AS_PAID_QUOTE_LOADING";
export const MARK_AS_PAID_QUOTE_SUCCESS = "MARK_AS_PAID_QUOTE_SUCCESS";
export const MARK_AS_PAID_QUOTE_ERROR = "MARK_AS_PAID_QUOTE_ERROR";

export const MAKE_REPAYMENT_QUOTE_LOADING = "MAKE_REPAYMENT_QUOTE_LOADING";
export const MAKE_REPAYMENT_QUOTE_SUCCESS = "MAKE_REPAYMENT_QUOTE_SUCCESS";
export const MAKE_REPAYMENT_QUOTE_ERROR = "MAKE_REPAYMENT_QUOTE_ERROR";

export const UPDATE_TRANSPORTATION_LOADING = "UPDATE_TRANSPORTATION_LOADING";
export const UPDATE_TRANSPORTATION_SUCCESS = "UPDATE_TRANSPORTATION_SUCCESS";
export const UPDATE_TRANSPORTATION_ERROR = "UPDATE_TRANSPORTATION_ERROR";

export const GET_ALL_INSTALLATION_COMPONENTS_LOADING =
  "GET_ALL_INSTALLATION_COMPONENTS_LOADING";
export const GET_ALL_INSTALLATION_COMPONENTS_SUCCESS =
  "GET_ALL_INSTALLATION_COMPONENTS_SUCCESS";
export const GET_ALL_INSTALLATION_COMPONENTS_ERROR =
  "GET_ALL_INSTALLATION_COMPONENTS_ERROR";

export const CREATE_INSTALLATION_COMPONENT_LOADING =
  "CREATE_INSTALLATION_COMPONENT_LOADING";
export const CREATE_INSTALLATION_COMPONENT_SUCCESS =
  "CREATE_INSTALLATION_COMPONENT_SUCCESS";
export const CREATE_INSTALLATION_COMPONENT_ERROR =
  "CREATE_INSTALLATION_COMPONENT_ERROR";

export const UPDATE_INSTALLATION_COMPONENT_LOADING =
  "UPDATE_INSTALLATION_COMPONENT_LOADING";
export const UPDATE_INSTALLATION_COMPONENT_SUCCESS =
  "UPDATE_INSTALLATION_COMPONENT_SUCCESS";
export const UPDATE_INSTALLATION_COMPONENT_ERROR =
  "UPDATE_INSTALLATION_COMPONENT_ERROR";

export const GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_LOADING =
  "GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_LOADING";
export const GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_SUCCESS =
  "GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_SUCCESS";
export const GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_ERROR =
  "GET_ALL_INSTALLATION_COMPONENTS_CATEGORIES_ERROR";

export const UPDATE_PRODUCT_PRICING_LOADING = "UPDATE_PRODUCT_PRICING_LOADING";
export const UPDATE_PRODUCT_PRICING_SUCCESS = "UPDATE_PRODUCT_PRICING_SUCCESS";
export const UPDATE_PRODUCT_PRICING_ERROR = "UPDATE_PRODUCT_PRICING_ERROR";

export const CREATE_COMMENT_LOADING = "CREATE_COMMENT_LOADING";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_ERROR = "CREATE_COMMENT_ERROR";

export const UPDATE_COMMENT_LOADING = "UPDATE_COMMENT_LOADING";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_ERROR = "UPDATE_COMMENT_ERROR";

export const GET_COMMENTS_BY_QUOTE_ID_LOADING =
  "GET_COMMENTS_BY_QUOTE_ID_LOADING";
export const GET_COMMENTS_BY_QUOTE_ID_SUCCESS =
  "GET_COMMENTS_BY_QUOTE_ID_SUCCESS";
export const GET_COMMENTS_BY_QUOTE_ID_ERROR = "GET_COMMENTS_BY_QUOTE_ID_ERROR";

export const GET_INSTALLATION_COMPONENTS_LOADING =
  "GET_INSTALLATION_COMPONENTS_LOADING";
export const GET_INSTALLATION_COMPONENTS_SUCCESS =
  "GET_INSTALLATION_COMPONENTS_SUCCESS";
export const GET_INSTALLATION_COMPONENTS_ERROR =
  "GET_INSTALLATION_COMPONENTS_ERROR";

export const CREATE_QUOTE_REQUEST_LOADING = "CREATE_QUOTE_REQUEST_LOADING";
export const CREATE_QUOTE_REQUEST_SUCCESS = "CREATE_QUOTE_REQUEST_SUCCESS";
export const CREATE_QUOTE_REQUEST_ERROR = "CREATE_QUOTE_REQUEST_ERROR";

export const COMPLETE_INSTALLATION_LOADING = "COMPLETE_INSTALLATION_LOADING";
export const COMPLETE_INSTALLATION_SUCCESS = "COMPLETE_INSTALLATION_SUCCESS";
export const COMPLETE_INSTALLATION_ERROR = "COMPLETE_INSTALLATION_ERROR";

// Inventory
export const CREATE_INWARD_PURCHASE_LOADING = "CREATE_INWARD_PURCHASE_LOADING";
export const CREATE_INWARD_PURCHASE_SUCCESS = "CREATE_INWARD_PURCHASE_SUCCESS";
export const CREATE_INWARD_PURCHASE_ERROR = "CREATE_INWARD_PURCHASE_ERROR";

export const GET_INWARD_PURCHASES_LOADING = "GET_INWARD_PURCHASES_LOADING";
export const GET_INWARD_PURCHASES_SUCCESS = "GET_INWARD_PURCHASES_SUCCESS";
export const GET_INWARD_PURCHASES_ERROR = "GET_INWARD_PURCHASES_ERROR";

export const GET_INWARD_PURCHASE_LOADING = "GET_INWARD_PURCHASE_LOADING";
export const GET_INWARD_PURCHASE_SUCCESS = "GET_INWARD_PURCHASE_SUCCESS";
export const GET_INWARD_PURCHASE_ERROR = "GET_INWARD_PURCHASE_ERROR";

export const GET_INVENTORY_STOCKS_LOADING = "GET_INVENTORY_STOCKS_LOADING";
export const GET_INVENTORY_STOCKS_SUCCESS = "GET_INVENTORY_STOCKS_SUCCESS";
export const GET_INVENTORY_STOCKS_ERROR = "GET_INVENTORY_STOCKS_ERROR";

export const GET_INVENTORY_STOCK_LOADING = "GET_INVENTORY_STOCK_LOADING";
export const GET_INVENTORY_STOCK_SUCCESS = "GET_INVENTORY_STOCK_SUCCESS";
export const GET_INVENTORY_STOCK_ERROR = "GET_INVENTORY_STOCK_ERROR";

export const CREATE_DISTRIBUTION_LOADING = "CREATE_DISTRIBUTION_LOADING";
export const CREATE_DISTRIBUTION_SUCCESS = "CREATE_DISTRIBUTION_SUCCESS";
export const CREATE_DISTRIBUTION_ERROR = "CREATE_DISTRIBUTION_ERROR";

export const GET_DISTRIBUTIONS_LOADING = "GET_DISTRIBUTIONS_LOADING";
export const GET_DISTRIBUTIONS_SUCCESS = "GET_DISTRIBUTIONS_SUCCESS";
export const GET_DISTRIBUTIONS_ERROR = "GET_DISTRIBUTIONS_ERROR";

export const GET_DISTRIBUTION_LOADING = "GET_DISTRIBUTION_LOADING";
export const GET_DISTRIBUTION_SUCCESS = "GET_DISTRIBUTION_SUCCESS";
export const GET_DISTRIBUTION_ERROR = "GET_DISTRIBUTION_ERROR";

export const BATCH_ASSIGN_LOCATION_LOADING = "BATCH_ASSIGN_LOCATION_LOADING";
export const BATCH_ASSIGN_LOCATION_SUCCESS = "BATCH_ASSIGN_LOCATION_SUCCESS";
export const BATCH_ASSIGN_LOCATION_ERROR = "BATCH_ASSIGN_LOCATION_ERROR";

// Maintenances
export const GET_MAINTENANCE_EQUIPMENT_LOADING =
  "GET_MAINTENANCE_EQUIPMENT_LOADING";
export const GET_MAINTENANCE_EQUIPMENT_SUCCESS =
  "GET_MAINTENANCE_EQUIPMENT_SUCCESS";
export const GET_MAINTENANCE_EQUIPMENT_ERROR =
  "GET_MAINTENANCE_EQUIPMENT_ERROR";

export const GET_CUSTOMER_MAINTENANCE_EQUIPMENT_LOADING =
  "GET_CUSTOMER_MAINTENANCE_EQUIPMENT_LOADING";
export const GET_CUSTOMER_MAINTENANCE_EQUIPMENT_SUCCESS =
  "GET_CUSTOMER_MAINTENANCE_EQUIPMENT_SUCCESS";
export const GET_CUSTOMER_MAINTENANCE_EQUIPMENT_ERROR =
  "GET_CUSTOMER_MAINTENANCE_EQUIPMENT_ERROR";

export const GET_MAINTENANCE_EQUIPMENT_ID_LOADING =
  "GET_MAINTENANCE_EQUIPMENT_ID_LOADING";
export const GET_MAINTENANCE_EQUIPMENT_ID_SUCCESS =
  "GET_MAINTENANCE_EQUIPMENT_ID_SUCCESS";
export const GET_MAINTENANCE_EQUIPMENT_ID_ERROR =
  "GET_MAINTENANCE_EQUIPMENT_ID_ERROR";
export const GET_MAINTENANCE_EQUIPMENT_IMAGES_LOADING =
  "GET_MAINTENANCE_EQUIPMENT_IMAGES_LOADING";
export const GET_MAINTENANCE_EQUIPMENT_IMAGES_SUCCESS =
  "GET_MAINTENANCE_EQUIPMENT_IMAGES_SUCCESS";
export const GET_MAINTENANCE_EQUIPMENT_IMAGES_ERROR =
  "GET_MAINTENANCE_EQUIPMENT_IMAGES_ERROR";
export const CLOSE_MAINTENANCE_EQUIPMENT_LOADING =
  "CLOSE_MAINTENANCE_EQUIPMENT_LOADING";
export const CLOSE_MAINTENANCE_EQUIPMENT_SUCCESS =
  "CLOSE_MAINTENANCE_EQUIPMENT_SUCCESS";
export const CLOSE_MAINTENANCE_EQUIPMENT_ERROR =
  "CLOSE_MAINTENANCE_EQUIPMENT_ERROR";

export const GET_CUSTOMER_KYC_DOCUMENTS_LOADING =
  "GET_CUSTOMER_KYC_DOCUMENTS_LOADING";
export const GET_CUSTOMER_KYC_DOCUMENTS_SUCCESS =
  "GET_CUSTOMER_KYC_DOCUMENTS_SUCCESS";
export const GET_CUSTOMER_KYC_DOCUMENTS_ERROR =
  "GET_CUSTOMER_KYC_DOCUMENTS_ERROR";

export const UPLOAD_UNDERTAKEN_LOADING = "UPLOAD_UNDERTAKEN_LOADING";
export const UPLOAD_UNDERTAKEN_SUCCESS = "UPLOAD_UNDERTAKEN_SUCCESS";
export const UPLOAD_UNDERTAKEN_ERROR = "UPLOAD_UNDERTAKEN_ERROR";

export const UPDATE_USER_KYC_LOADING = "UPDATE_USER_KYC_LOADING";
export const UPDATE_USER_KYC_SUCCESS = "UPDATE_USER_KYC_SUCCESS";
export const UPDATE_USER_KYC_ERROR = "UPDATE_USER_KYC_ERROR";

export const UPDATE_REFERRAL_LOADING = "UPDATE_REFERRAL_LOADING";
export const UPDATE_REFERRAL_SUCCESS = "UPDATE_REFERRAL_SUCCESS";
export const UPDATE_REFERRAL_ERROR = "UPDATE_REFERRAL_ERROR";

export const UPDATE_EXPIRED_QUOTE_LOADING = "UPDATE_EXPIRED_QUOTE_LOADING";
export const UPDATE_EXPIRED_QUOTE_SUCCESS = "UPDATE_EXPIRED_QUOTE_SUCCESS";
export const UPDATE_EXPIRED_QUOTE_ERROR = "UPDATE_EXPIRED_QUOTE_ERROR";

export const GET_SALES_TEAM_PAID_COMMISSIONS_LOADING =
  "GET_SALES_TEAM_PAID_COMMISSIONS_LOADING";
export const GET_SALES_TEAM_PAID_COMMISSIONS_SUCCESS =
  "GET_SALES_TEAM_PAID_COMMISSIONS_SUCCESS";
export const GET_SALES_TEAM_PAID_COMMISSIONS_ERROR =
  "GET_SALES_TEAM_PAID_COMMISSIONS_ERROR";
